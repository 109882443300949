@import '@angular/material/theming';
@include mat-core();

@font-face {
  font-family: 'MyMealFont';
  src: url('/assets/font/Nunito.ttf');
}

@media print {
  .not-printable, header, {
    display: none !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
  }

  .app__outlet {
    padding: 0 !important;
  }

  .printSection, .printSection * {
    display: block;
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  @page {
    margin: 0;
    size: auto;
  }

  .dynamic__stretch--view {
    width: 100% !important;
    height: auto !important;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
  background-size: cover;
}

label {
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .3);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, .1) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mdc-radio__outer-circle{
  border-color: black;
}

.mdc-form-field {
  font-family: "Avenir Next", sans-serif;
  font-weight: 500;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color: black;
  background-color: black;
}

.mdc-radio__inner-circle {
  border-color: black;
}

.mat-ripple-element {
  display: none !important;
}

dynamo-form {
  display: block;
  max-width: 80vw !important;
}

.mat-mdc-select-panel {
  background-color: white;
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: -2rem !important;
}

.mdc-list-item--selected {
  background-color: rgba(0, 0, 0, .1);
}

.active {
  background-color: #54656c;
  border-radius: .5rem;
}

.list-menu-item--active {
  background-color: #54656c;
  border-radius: .5rem;
  color: black;
}

.dynamo__list-menu__list-menu-item__button {
  width: 100%;
}

mat-form-field * {
  color: black !important;
  height: 2rem;
  font-family: 'MyMealFont', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.dynamo__dynamic__form__field__submit {
  height: 3rem;
  width: fit-content;
  border-radius: .3rem !important;
  background-color: rgba(0, 0, 0, .1) !important;
  font-family: "MyMealFont", monospace !important;
  cursor: pointer;
  padding: 1rem !important;
  font-size: 19px !important;
  color: black !important;
  display: flex !important;
  vertical-align: center !important;
  align-items: center !important;
}

mat-select * {
  box-shadow: none !important;
  outline: none !important;
  color: black !important;
}

mat-option {
  background-color: white;
  border-radius: 0 !important;
  color: black !important;
}


input:-webkit-autofill {
  -webkit-box-shadow: transparent;
  -webkit-text-fill-color: black;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: transparent;
  -webkit-text-fill-color: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  color: black !important;
}

/* SkillBase style wrapper */

.dynamic {
  &__tile {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
    border-radius: .2rem;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  &__display {
    &--none {
      display: none !important;
    }
  }

  &__text {
    &--bold {
      font-weight: 700 !important;
    }

    &--italic {
      font-style: italic !important;
    }

    &--center {
      text-align: center !important;
    }

    &--end {
      text-align: end !important;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--link {
      text-decoration: underline;
      cursor: pointer;
    }

    &--accent {
      opacity: .7;
    }

    &--white {
      color: white !important;
    }
  }

  &__button {
    padding: 12px 1rem;
    box-sizing: border-box;
    background-color: #000000;
    color: white;
    border-radius: .5rem;
    border: 0;
    cursor: pointer;
    font-family: 'MyMealFont', Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;

    &--disabled {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__no-shadow {
    box-shadow: none !important;
  }

  &__background {
    &--transparent {
      background-color: transparent !important;
    }

    &--red {
      background-color: darkred;
    }
  }

  &__position {
    &--relative {
      position: relative !important;
    }

    &--absolute {
      position: absolute !important;
    }

    &--fixed {
      position: fixed !important;
    }
  }

  &__padding {
    &--lg {
      padding: 3rem !important;
    }

    &--md {
      padding: 2rem !important;
    }

    &--sm {
      padding: 1rem !important;
    }

    &--xs {
      padding: .4rem !important;
    }

    &--none {
      padding: 0 !important;
    }

    &--vertical--lg {
      padding: 3rem 0 3rem 0 !important;
    }

    &--vertical--md {
      padding: 2rem 0 2rem 0 !important;
    }

    &--vertical--sm {
      padding: 1rem 0 1rem 0 !important;
    }

    &--vertical--xs {
      padding: .4rem 0 .4rem 0 !important;
    }

    &--horizontal--lg {
      padding: 0 3rem 0 3rem !important;
    }

    &--horizontal--md {
      padding: 0 2rem 0 2rem !important;
    }

    &--horizontal--sm {
      padding: 0 1rem 0 1rem !important;
    }

    &--horizontal--xs {
      padding: 0 .4rem 0 .4rem !important;
    }
  }

  &__margin {
    &--none {
      margin: 0 !important;
    }

    &--top {
      &-lg {
        margin: 3rem 0 0 0 !important;
      }

      &-md {
        margin: 2rem 0 0 0 !important;
      }

      &-sm {
        margin: 1rem 0 0 0 !important;
      }
    }
  }

  &__width-min {
    &--none {
      min-width: fit-content !important;
    }

    &--full {
      min-width: 100% !important;
    }
  }

  &__title {
    margin: 0;
    font-family: 'MyMealFont', Montserrat, sans-serif;
    color: black;
    font-size: 50px;
    max-width: 90vw;
    word-break: break-word;
    opacity: .9;

    @media(max-width: 840px) {
      font-size: 40px;
    }

    &--sm {
      margin: 0;
      font-family: 'MyMealFont', Montserrat, sans-serif;
      color: black;
      font-size: 32px;
      max-width: 90vw;
      word-break: break-word;
      white-space: break-spaces;
      opacity: .9;
      font-weight: 700;

      @media(max-width: 840px) {
        font-size: 32px;
      }
    }
  }

  &__text {
    margin: 0;
    font-family: 'MyMealFont', Montserrat, sans-serif;
    color: black;
    text-align: start;
    font-size: 25px;
    max-width: 80vw;
    word-break: break-word;

    &--sm {
      margin: 0;
      font-family: 'MyMealFont', Montserrat, sans-serif;
      color: black;
      font-size: 20px;
      max-width: 80vw;
      white-space: break-spaces;
      word-break: break-word;
    }

    &--xs {
      margin: 0;
      font-family: 'MyMealFont', Montserrat, sans-serif;
      color: black;
      font-size: 17px;
      max-width: 80vw;
      font-weight: 400;
      white-space: break-spaces;
      word-break: break-word;
    }

    &--xxs {
      margin: 0;
      font-family: 'MyMealFont', Montserrat, sans-serif;
      color: black;
      font-size: 14px;
      max-width: 80vw;
      font-weight: 300;
      line-height: 80%;
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  &__context {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: black !important;
    font-size: 20px;
  }

  &__list-view {
    &__text {
      &--sm {
        font-size: 14px;
      }
    }
  }

  &__icon {
    &__sm {
      font-size: 1.3rem;
      height: 1.3rem;
      width: 1.3rem;
      vertical-align: -26% !important;
    }

    &--active {

    }
  }

  &__stretch {
    width: 100%;
    height: 100%;

    &--height {
      height: 100% !important;
    }

    &--width {
      width: 100% !important;
    }
  }

  &__stretch--view {
    width: 100vw;
    min-height: 100vh;
  }

  &__view {
    &--height {
      &-fit-content {
        height: fit-content !important;
      }
    }

    &--width {
      &-fit-content {
        width: fit-content !important;
      }
    }
  }

  &__opacity {
    &--less {
      opacity: .8 !important;
    }

    &--full {
      opacity: 1 !important;
    }
  }

  &__element {
    &--highlight {
      background-color: #54656c !important;
      color: whitesmoke !important;
    }

    &--pointer {
      cursor: pointer;
    }
  }

  &__divider {
    &--vertical {
      width: 1px;
      height: 100%;
      background-color: rgba(0, 0, 0, .07);
    }

    &--horizontal {
      height: 1px;
      width: 100%;
      background-color: #54656c;
      opacity: .3;
    }
  }

  @media(max-width: 800px) {
    &__tile {
      width: 80vw;
      min-width: 80vw;
      max-width: 80vw;
    }
  }
}
